import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { CompanyListingInterface } from "../../interface/company.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyDataService } from "../../data-service/company.data.service";
import { AlertService } from "../../service/alert.service";

@Component({
  selector: "unlimited-plan-dialog",
  templateUrl: "unlimited-plan-dialog.html",
  styleUrls: ["unlimited-plan-dialog.scss"],
})
export class UnlimitedPlanDialog {

  durationOption: "M" | "y" | "c" | "w" | "Q" | "s" = "y";
  duration: number = 1;
  startDate: Date = new Date();
  endDate: Date
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    companyList: CompanyListingInterface[],
    plan: "FREE" | "UNLIMITED"
  },
    private dialogRef: MatDialogRef<UnlimitedPlanDialog>,
    private companyDataService: CompanyDataService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,) {
    if (this.data.plan == "FREE") this.durationOption = "s";
    this.durationChange();
  }

  durationChange() {
    if (this.startDate) {
      if (this.durationOption != "c") {
        let end = moment(this.startDate).add(this.duration, this.durationOption);
        this.endDate = end.toDate();
      }
    }
  }

  getPlanName() {
    if (this.data.plan == "FREE") return "free";
    else return "unlimited";
  }

  getTitle() {
    if (this.data.companyList.length == 1) {
      return this.data.companyList[0].name
    }
    return `${this.data.companyList.length} selected company`;
  }

  save() {
    let obj = {
      ids: this.data.companyList.map(x => x.id),
      startDate: this.startDate,
      endDate: this.endDate,
      package: this.data.plan
    }
    if (this.durationOption == "s") {
      obj.startDate = null;
      obj.endDate = null;
    }

    this.ngxSpinnerService.show();
    this.companyDataService.changePlan(obj).subscribe({
      next: () => {
        this.alertService.openSnackBar("Successfully updated");
        this.ngxSpinnerService.hide();
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.ngxSpinnerService.hide();

        this.alertService.openSnackBar(err.error);
      },
    });

  }
}
