import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AppFullComponent } from "./layouts/full/full.component";
import { AuthGuard } from "./share/service/guard.service";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: AppFullComponent,
    children: [
      {
        path: "",
        redirectTo: "/dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
    ],
  },
  {
    path: "",
    component: AppBlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "authentication/404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled' //scroll to the top
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
