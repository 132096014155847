import { NgModule } from "@angular/core";
import { BackPageDirective } from "./back-page.direactive";
import { CallToDirective } from "./call-to.directive";
import { ScreenDirective } from "./device.directive";
import { EmailToDirective } from "./email-to.directive";
import { WhatsAppToDirective } from "./ws-to.directive";

@NgModule({

  declarations: [
    EmailToDirective,
    CallToDirective,
    WhatsAppToDirective,
    ScreenDirective,
    BackPageDirective
  ],
  exports: [
    EmailToDirective,
    CallToDirective,
    WhatsAppToDirective,
    ScreenDirective,
    BackPageDirective
  ],
})
export class CustomDirectiveModule { }