import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/share/service/alert.service';
import { AuthService } from 'src/app/share/service/auth.service';
import { UserService } from 'src/app/share/service/user.service';
import { DrawerService } from '../../share/service/drawer.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  title: string;

  defaultCompanyImage = "assets/image/default-empty.png";
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  
  constructor(
    private router: Router,
    public authService: AuthService,
    public drawerService: DrawerService,
    public userService: UserService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,) {
    router.events.subscribe((event) => {

      if (event instanceof ActivationEnd) {
        const snapshot = event.snapshot;
        let value = snapshot.data.title;
        if (value) {
          this.title = value;
        }
      }

    });
  }

}
