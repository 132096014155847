import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material-module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { EmptyRecordModule } from "../share/component/empty-record/empty-record.module";
import { FormsModule } from "@angular/forms";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    MaterialModule,
    DashboardRoutingModule,
    FlexLayoutModule,
    EmptyRecordModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    EmptyRecordModule
  ],
})
export class DashboardModule { }
