import { Component } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyDataService } from "src/app/share/data-service/company.data.service";
import {
  ConfirmationDialog,
  ConfirmationDialogModel,
} from "src/app/share/dialog/confirmation-dialog/confirmation.dialog";
import { UnlimitedPlanDialog } from "src/app/share/dialog/unlimited-plan-dialog/unlimited-plan-dialog";
import { CompanyListingInterface } from "src/app/share/interface/company.interface";
import { AlertService } from "src/app/share/service/alert.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  list: CompanyListingInterface[] = [];
  filterList: CompanyListingInterface[] = [];
  isLoading: boolean;

  displayedColumns: string[] = [
    "check",
    "date",
    "name",
    "regNo",
    "owner",
    "duration",
    "package",
    "action",
  ];

  statusList = ["All Status", "Active", "Deactivated", "Expired"];
  packageList = ["All Plans", "Free Plan", " Unlimited Plan"];

  statusIndex = 0;
  packageIndex = 0;

  startDate: Date;
  endDate: Date;

  search: string;

  selectedId: string[] = [];

  constructor(
    private companyDataService: CompanyDataService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {
    this.getData();
  }

  private getData() {
    this.list = [];
    this.filterList = [];
    this.isLoading = true;
    this.companyDataService.getListing().subscribe({
      next: (x) => {
        this.list = x;
        this.isLoading = false;
        this.searchChanged();
      },
      error: (err) => {
        this.isLoading = false;

        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private changeStatus(ids: string[], isActive: boolean) {
    this.ngxSpinnerService.show();
    this.companyDataService.changeStatus(ids, isActive).subscribe({
      next: () => {
        this.alertService.openSnackBar("Successfully updated");

        this.ngxSpinnerService.hide();
        this.getData();
      },
      error: (err) => {
        this.ngxSpinnerService.hide();

        this.alertService.openSnackBar(err.error);
      },
    });
  }

  searchChanged() {
    this.filterList = Object.assign([], this.list);
    if (this.search) {
      let txt = this.search.trim().toLowerCase();
      this.filterList = this.filterList.filter(
        (x) =>
          x.name?.toLowerCase().includes(txt) ||
          x.owner?.toLowerCase().includes(txt) ||
          x.regNo?.toLowerCase().includes(txt)
      );
    }

    if (this.statusIndex == 1) {
      this.filterList = this.filterList.filter((x) => x.isActive);
    } else if (this.statusIndex == 2) {
      this.filterList = this.filterList.filter((x) => !x.isActive);
    }
    else if (this.statusIndex == 3) {
      this.filterList = this.filterList.filter((x) => x.isExpired);
    }

    if (this.packageIndex == 1) {
      this.filterList = this.filterList.filter((x) => x.package == "FREE");
    } else if (this.packageIndex == 2) {
      this.filterList = this.filterList.filter((x) => x.package == "UNLIMITED");
    }

    if (this.startDate) {
      this.filterList = this.filterList.filter((x) => {
        let date = new Date(x.createdDate);
        let startDate = this.startDate;
        let endDate = this.endDate;
        date.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        return date >= startDate && date <= endDate;
      });
    }
  }

  clearSearch() {
    this.search = null;
    this.searchChanged();
  }

  clearDateFilter() {
    this.startDate = null;
    this.endDate = null;
    this.searchChanged();
  }

  closeDatePicker() {
    if (this.startDate && !this.endDate) this.endDate = this.startDate;
    this.searchChanged();
  }

  selectedChange(e: MatCheckboxChange) {
    let value = e.source.value;

    if (e.checked) {
      this.selectedId.push(value);
    } else {
      let index = this.selectedId.findIndex((x) => x == value);
      if (index >= 0) {
        this.selectedId.splice(index, 1);
      }
    }
  }

  isSelected(val: string) {
    return this.selectedId.some((x) => x == val);
  }

  selectAll(e: MatCheckboxChange) {
    if (e.checked) {
      this.selectedId = this.filterList.map((x) => x.id);
    } else this.selectedId = [];
  }

  isSelectedAll() {
    return this.selectedId.length == this.filterList.length;
  }

  isIndeterminate() {
    if (this.isSelectedAll()) return false;
    return this.selectedId.length > 0;
  }

  active(val: CompanyListingInterface) {
    let data: ConfirmationDialogModel = {
      title: `Active ${val.name}`,
      content: "Are you sure want to active",
      boldContent: val.name,
      okBtnText: "Ok",
    };
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) this.changeStatus([val.id], true);
    });
  }

  deactive(val: CompanyListingInterface) {
    let data: ConfirmationDialogModel = {
      title: `Deactive ${val.name}`,
      content: "Are you sure want to deactive",
      boldContent: val.name,
      btnColor: "warn",
      okBtnText: "Ok",
    };
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) this.changeStatus([val.id], false);
    });
  }

  toFreePlan(val: CompanyListingInterface) {
    this.openChangePlanDialog([val], "FREE");

  }

  toUnlimitedPlan(val: CompanyListingInterface) {
    this.openChangePlanDialog([val], "UNLIMITED");

  }

  activeMutiple() {
    if (this.selectedId.length == 1) {
      this.active(this.filterList.find((x) => x.id == this.selectedId[0]));
      return;
    }
    let data: ConfirmationDialogModel = {
      title: `Active ${this.selectedId.length} record has selected`,
      content: "Are you sure want to active",
      okBtnText: "Ok",
    };
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) this.changeStatus(this.selectedId, true);
    });
  }

  deactiveMutiple() {
    if (this.selectedId.length == 1) {
      this.deactive(this.filterList.find((x) => x.id == this.selectedId[0]));
      return;
    }
    let data: ConfirmationDialogModel = {
      title: `Deactive ${this.selectedId.length} record has selected`,
      content: "Are you sure want to deactive",
      btnColor: "warn",
      okBtnText: "Ok",
    };
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) this.changeStatus(this.selectedId, false);
    });
  }

  toFreePlanMutiple() {
    this.openChangePlanDialog(this.list.filter(x => this.selectedId.includes(x.id)), "FREE");
  }

  toUnlimitedPlanMutiple() {
    this.openChangePlanDialog(this.list.filter(x => this.selectedId.includes(x.id)), "UNLIMITED");
  }

  openChangePlanDialog(x: CompanyListingInterface[], plan: "FREE" | "UNLIMITED") {
    this.dialog.open(UnlimitedPlanDialog, {
      data: {
        companyList: x,
        plan
      }
    }).afterClosed().subscribe((x) => {
      if (x) this.getData();
    })

  }
}
