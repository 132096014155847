import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from "src/app/share/service/alert.service";
import { AuthService } from "../../share/service/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
  success: boolean;
  otpIsSuccess: boolean;
  formGroup: FormGroup;
  hidePassword: boolean = true;
  hideConfrimPassword: boolean = true;
  passwordFrmGrp: FormGroup;
  otp: string;

  sec = 0;
  interval;
  constructor(authService: AuthService, private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
 private router: Router) {
    if (authService.isLoggedIn()) {
      location.href = "/";
    }
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
    this.passwordFrmGrp = new FormGroup({
      password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]),
      confrimPassword: new FormControl(null, [Validators.required]),
    }, {
      validators: this.confirmPass.bind(this)
    });
  }
  confirmPass(formGroup: FormGroup) {
    if (formGroup.value.confrimPassword && formGroup.value.password) {
      let status = formGroup.value.confrimPassword != formGroup.value.password;
      if (status) {
        formGroup.controls.confrimPassword.setErrors({ "match": true });
      }
      else
        formGroup.controls.confrimPassword.setErrors(null);
    }
  }

  reset() {
    // if (this.formGroup.invalid) return;
    // this.ngxSpinnerService.show();
    // this.memberDataService.sendResetPasswordByEmail(this.formGroup.value.email).subscribe({
    //   next: () => {
    //     this.ngxSpinnerService.hide();
    //     this.success = true;
    //     this.sec = 60;
    //     this.interval = setInterval(() => {
    //       this.sec--;
    //       if (this.sec == 0) {
    //         clearInterval(this.interval);
    //       }
    //     }, 1000)

    //   },
    //   error: (err) => {
    //     this.ngxSpinnerService.hide();
    //     this.alertService.openSnackBar(err.error);
    //   }
    // });
  }

  onCodeCompleted(e) {
    // this.otp = e;
    // this.ngxSpinnerService.show();
    // this.memberDataService.resetPasswordVerify(this.formGroup.value.email, e).subscribe({
    //   next: () => {
    //     this.ngxSpinnerService.hide();
    //     this.otpIsSuccess = true;

    //   },
    //   error: (err) => {
    //     this.ngxSpinnerService.hide();
    //     this.alertService.openSnackBar(err.error);
    //   }
    // });
  }

  save() {
    // if (this.passwordFrmGrp.invalid) return;

    // this.ngxSpinnerService.show();
    // this.memberDataService.resetPassword(this.formGroup.value.email, this.otp, this.passwordFrmGrp.value.password).subscribe({
    //   next: () => {
    //     this.ngxSpinnerService.hide();
    //     this.alertService.openSnackBar("Successfully reset password");
    //     this.router.navigate(["/"]);
    //   },
    //   error: (err) => {
    //     this.ngxSpinnerService.hide();
    //     this.alertService.openSnackBar(err.error);
    //   }
    // });
  }
}
