import { AfterContentChecked, Component, HostListener, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { UserService } from "../../share/service/user.service";
import { DrawerService } from "../../share/service/drawer.service";
import { NavigationEnd, Router } from "@angular/router";


@Component({
  selector: "app-full",
  templateUrl: "./full.component.html",
  styleUrls: [],
})
export class AppFullComponent implements AfterContentChecked {
  @ViewChild("drawer") public drawer: MatDrawer;
  screenWidth: number;
  isHideHeader: boolean;
  constructor(
    private drawerService: DrawerService,
    private userService: UserService,
    router: Router,
  ) {
    this.screenWidth = window.innerWidth;

    this.userService.onInit();

    router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.hideHeader();
      }

    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.hideHeader();
  }
  ngAfterContentChecked(): void {
    this.drawerService.drawer = this.drawer;
  }
  active() {
    setTimeout(() => {
      document.getElementById("page-container").scrollTo(0, 0)
    }, 0);
  }

  private hideHeader() {
    if (!this.isMobile()) {
      this.isHideHeader = false;
    }
    else {
      setTimeout(() => {
        let ele = document.getElementsByClassName("page-header");
        this.isHideHeader = ele.length != 0;
      }, 10);
    }
  }

  private isMobile() {
    return this.screenWidth <= 768;
  }

}
