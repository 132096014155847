import { HostListener } from "@angular/core";
import { Directive, Input } from "@angular/core";

@Directive({
    selector:"[wsTo]"
})
export class WhatsAppToDirective{
    @Input("wsTo") phone:string;

    @HostListener("click")
    onClick(){
        window.open(`https://api.whatsapp.com/send?phone=${this.phone}`);
    }
}