<div class="login-page">
  <div class="login-form">

    <form [formGroup]="formGroup" (ngSubmit)="login()">
      <div class="logo-container">
        <img src="assets/image/logo.png" class="login-logo" alt="" />
        <div>
          Manage business accounting smarter
        </div>
      </div>
      <mat-card class="form-field">

        <div class="sign-in-text">Administrator Login</div>
        <mat-card-content>
          <div class="inform mobile-full">
            <mat-label>Email</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput placeholder="Enter your email" required formControlName="userName" type="email" />
              <mat-error>Email is required</mat-error>
            </mat-form-field>
          </div>
          <div class="inform mobile-full">
            <mat-label>Password</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput [type]="hide ? 'password' : 'text'" placeholder="Enter your password"
                formControlName="password" required />
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
            </mat-form-field>
          </div>


          <div>
            <!-- <div class="forgot-password-link" routerLink="/authentication/forgot-password">
              Forgot Password
            </div> -->
            <section>
              <mat-checkbox color="primary" formControlName="remember">Remember Me</mat-checkbox>
            </section>
          </div>
          <button mat-raised-button color="primary" class="lg">Login</button>
        </mat-card-content>
      </mat-card>
    </form>
  </div>

</div>