import { Directive, HostListener } from '@angular/core';
import { DrawerService } from '../service/drawer.service';

@Directive({
  selector: '[appAppDrawer]'
})
export class AppDrawerDirective {

  constructor(private drawerService:DrawerService) { }
  @HostListener('click', []) onClick() {
    if(window.innerWidth<=768) {
        if(this.drawerService.drawer.opened) {
          this.drawerService.drawer.close();
        }
    }
  }
}
