import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { AdministratorDataService } from "../data-service/administrator.data.service";
import { UserInterface } from "../interface/user.interface";
import { AlertService } from "./alert.service";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private key = "Bhs6bPxcki";

    private loginInfoKey = "pw_uid";

    user: UserInterface;

    constructor(
        private authService: AuthService,
        private alertService: AlertService,
        private administratorDataService: AdministratorDataService) {
    }
    onInit(): void {
        this.getProfile();

    }



    getProfile() {
        this.administratorDataService.getProfile().subscribe({
            next: (x) => {
                this.user = x;
            },
            error: (err) => {
                this.alertService.openSnackBar(err.error);
            }
        });
    }

    rememberLoginInfo(value: any) {

        let str = CryptoJS.AES.encrypt(JSON.stringify(value), this.key).toString();
        localStorage.setItem(this.loginInfoKey, str);
    }
    resetLoginInfo() {
        localStorage.removeItem(this.loginInfoKey);
    }

    getLoginInfo(): any | undefined {
        let text = localStorage.getItem(this.loginInfoKey);
        if (!text) return undefined;
        try {
            let bytes = CryptoJS.AES.decrypt(text, this.key);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            console.log(error);
            return undefined;
        }
    }

    login(token: string) {
        this.authService.setToken(token);
    }

    loginGoTo(token: string) {
        this.login(token);
        location.href = '/';
    }
}