import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material-module";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AuthenticationModule } from "./authentication/authentication.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AppFullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/header/header.component";
import { AppDrawerComponent } from "./layouts/drawer/drawer.component";
import { ConfirmationDialog } from "./share/dialog/confirmation-dialog/confirmation.dialog";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { HttpRequestInterceptor } from "./share/service/http.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SingleTextInputDialog } from "./share/dialog/single-text-input-dialog/single-text-input-dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppDrawerDirective } from './share/directive/app-drawer.directive';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { CustomDirectiveModule } from "./share/directive/custom-directive.module";
import { UnlimitedPlanDialog } from "./share/dialog/unlimited-plan-dialog/unlimited-plan-dialog";
import { NgxMaskModule } from "ngx-mask";


@NgModule({
    declarations: [
        AppComponent,
        AppBlankComponent,
        AppFullComponent,
        AppHeaderComponent,
        AppDrawerComponent,
        ConfirmationDialog,
        SingleTextInputDialog,
        AppDrawerDirective,
        UnlimitedPlanDialog
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FlexLayoutModule,
        AuthenticationModule,
        DashboardModule,
        HttpClientModule,
        NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        CustomDirectiveModule,
        NgxMaskModule.forRoot(),
        ServiceWorkerModule.register("/ngsw-worker.js", {
            enabled: environment.production,
        }),
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-MY" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
