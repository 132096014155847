import { HostListener } from "@angular/core";
import { Directive } from "@angular/core";

@Directive({
    selector: "[backPage]"
})
export class BackPageDirective {

    @HostListener("click")
    onClick() {
        window.history.back();
    }
}