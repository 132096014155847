const env = {
  local: {
    production: false,
    url: "https://localhost:5001"
  },
  dev: {
    production: false,
    url: "https://shoebox-admin-dev-api.beyonary.com"
  },
  uat:{
    production: false,
    url: "https://uat-admin-api.shoebox.my"
  },
  prod: {
    production: false,
    url: "https://admin-api.shoebox.my"
  }
};

export const environment = env.prod;
