import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../share/service/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../../share/service/auth.service";
import { AlertService } from "../../share/service/alert.service";
import { AdministratorDataService } from "src/app/share/data-service/administrator.data.service";



@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  hide: boolean = true;
  formGroup: FormGroup;
  socialAuth: boolean = false;

  constructor(
    private userService: UserService,
    authService: AuthService,
    private ngxSpinnerService: NgxSpinnerService,
    private administratorDataService:AdministratorDataService,
    private alertService: AlertService,
  ) {
    if (authService.isLoggedIn()) {
      location.href = "/";
    }
  }

  ngOnInit(): void {
    let info = this.userService.getLoginInfo();
    this.formGroup = new FormGroup({
      userName: new FormControl(info?.userName ?? null, [Validators.required]),
      password: new FormControl(info?.password ?? null, [Validators.required]),
      remember: new FormControl(info ? true : false),
    });
  }

  socialLogin(providerId: string) {

  }

  login() {

    if (this.formGroup.invalid) return;

    this.ngxSpinnerService.show();
    let value = this.formGroup.value;

    this.administratorDataService.login(value.userName, value.password).subscribe({
      next: (x) => {
        if (value.remember)
          this.userService.rememberLoginInfo({ userName: value.userName, password: value.password });
        else
          this.userService.resetLoginInfo();

        this.ngxSpinnerService.hide();
        this.userService.loginGoTo(x);

      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
      }
    });

  }

}
