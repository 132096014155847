<div class="drawer-content">
  <div class="logo-container">
    <img src="assets/image/logo.png" />
  </div>
  <mat-nav-list>
    <div class="login-user" *ngIf="userService.user">
      <img [src]="userService.user.photoUrl ?? 'assets/image/blank-user-img.png'" alt="" />
      <div class="name" [matMenuTriggerFor]="menu">
        {{userService.user?.name}}
        <mat-icon>arrow_drop_down</mat-icon>

        <mat-menu #menu="matMenu" xPosition="before">
          <!-- <button appAppDrawer mat-menu-item routerLink="/general/edit-profile">Edit Profile</button>
          <button appAppDrawer mat-menu-item routerLink="/general/change-password"
            *ngIf="userService.user.provider =='EMAIL'">Change Password</button> -->
          <button appAppDrawer mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </div>
      <div class="email"> {{userService.user?.email}}</div>
    </div>
    <mat-list-item routerLinkActive="active" appAppDrawer routerLink="dashboard">
      <mat-icon mat-list-icon>business</mat-icon>
      <div class="title" mat-line>Company</div>
    </mat-list-item>

  </mat-nav-list>
</div>