<div class="content-container with-float">
    <mat-card class="table-list">
        <div class="table-search-container">
            <div class="search-wrapper">
                <mat-icon>search</mat-icon>
                <input placeholder="Search here..." [(ngModel)]="search" (ngModelChange)="searchChanged()">
                <mat-icon *ngIf="search" (click)="clearSearch()">close</mat-icon>
            </div>

            <div class="date-picker-wrapper">
                <button mat-icon-button (click)="picker1.open()">
                    <mat-icon>event</mat-icon>
                </button>
                <mat-form-field floatLabel="false">
                    <mat-date-range-input [rangePicker]="picker1">
                        <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                        <input matEndDate placeholder="End date" [(ngModel)]="endDate">
                    </mat-date-range-input>
                </mat-form-field>
                <mat-date-range-picker #picker1 (closed)="closeDatePicker()"></mat-date-range-picker>
                <button mat-icon-button class="remove-date" color="warn" *ngIf="endDate" (click)="clearDateFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>


            <div class="search-wrapper" style="width: 150px;margin-left: 10px;">
                <mat-select [(ngModel)]="packageIndex" (ngModelChange)="searchChanged()">
                    <mat-option *ngFor="let item of packageList; let i = index" [value]="i">{{item}}</mat-option>
                </mat-select>
            </div>

            <div class="search-wrapper" style="width: 150px;margin-left: 10px;">
                <mat-select [(ngModel)]="statusIndex" (ngModelChange)="searchChanged()">
                    <mat-option *ngFor="let item of statusList; let i = index" [value]="i">{{item}}</mat-option>
                </mat-select>
            </div>
        </div>
    </mat-card>


    <div class="desktop-page-header" *ngIf="selectedId.length">

        <div fxFlex fxLayoutAlign=" center">

            <button mat-mini-fab type="button" (click)="selectedId = [];">
                <mat-icon>close</mat-icon>
            </button>
            <div class="bread-crumb">
                <b>{{selectedId.length}} </b> Selected
            </div>
        </div>
        <button mat-mini-fab type="button" matTooltip="Active" (click)="activeMutiple()">
            <mat-icon>check_circle</mat-icon>
        </button>
        <button mat-mini-fab type="button" matTooltip="Deactivate" (click)="deactiveMutiple()">
            <mat-icon>do_disturb_on</mat-icon>
        </button>
        <button mat-mini-fab type="button" matTooltip="Downgrade to Free Plan" (click)="toFreePlanMutiple()">
            <mat-icon>star_outline</mat-icon>
        </button>
        <button mat-mini-fab type="button" matTooltip="Upgrade to Unlimited Plan" (click)="toUnlimitedPlanMutiple()">
            <mat-icon>star</mat-icon>
        </button>


    </div>



    <mat-card class="table-list" style="overflow-y: scroll;
    height: calc(100vh - 256px);padding: 0px !important;">

        <table mat-table [dataSource]="filterList">

            <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="selectAll($event)" [checked]="isSelectedAll()"
                        [indeterminate]="isIndeterminate()"></mat-checkbox>
                </th>
                <td mat-cell [ngClass]="{'inactive': !element.isActive}" *matCellDef="let element" class="xsTitle-f">
                    <mat-checkbox (change)="selectedChange($event)" [value]="element.id"
                        [checked]="isSelected(element.id)"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Created Date </th>
                <td mat-cell *matCellDef="let element" class="xsTitle-f" [ngClass]="{'inactive': !element.isActive}">
                    {{element.createdDate | date:
                    'dd/MM/yyyy'}}
                </td>
            </ng-container>


            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Company Name </th>
                <td mat-cell *matCellDef="let element" class="xsTitle-f" style="width: 20%;"
                    [ngClass]="{'inactive': !element.isActive}">{{element.name}} </td>
            </ng-container>


            <ng-container matColumnDef="regNo">
                <th mat-header-cell *matHeaderCellDef class="xsTitle-f"> SSM Reg. No. </th>
                <td mat-cell *matCellDef="let element" style="width: 20%;" [ngClass]="{'inactive': !element.isActive}">
                    {{element.regNo}}
                </td>
            </ng-container>

            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef class="xsTitle-f"> Created By </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'inactive': !element.isActive}">
                    {{element.owner}}
                </td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef class="xsTitle-f"> Duration</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'inactive': !element.isActive}">

                    <span *ngIf="element.startDate">
                        {{element.startDate | date:"dd/MM/yyyy"}} -{{element.endDate | date:"dd/MM/yyyy"}}
                    </span>
                    <span *ngIf="!element.startDate">
                        Unlimited Duration
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="package">
                <th mat-header-cell *matHeaderCellDef class="xsTitle-f"> Package </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'inactive': !element.isActive}">

                    <span class="user-plan" *ngIf="element.package =='FREE'">
                        Free
                    </span>

                    <span class="user-plan gold" *ngIf="element.package !='FREE'">
                        Unlimited
                    </span>


                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="xsTitle-f"> Status </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'inactive': !element.isActive}" >
                    {{element.isActive?'Active':"Inactive"}}
                </td>
            </ng-container> -->

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'inactive': !element.isActive}">

                    <button mat-icon-button matTooltip="Activate" *ngIf="!element.isActive" (click)="active(element)">
                        <mat-icon class="color-green">check_circle</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Deactivate" *ngIf="element.isActive"
                        (click)="deactive(element)">
                        <mat-icon class="color-red">do_disturb_on</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Downgrade to Free Plan" *ngIf="element.package !='FREE'"
                        (click)="toFreePlan(element)">
                        <mat-icon class="color-orange">star</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Upgrade to Unlimited Plan" *ngIf="element.package =='FREE'"
                        (click)="toUnlimitedPlan(element)">
                        <mat-icon class="color-grey">star_outline</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9999">
                    <app-empty-record *ngIf="!isLoading && filterList.length==0" disableSubTitle></app-empty-record>

                </td>
            </tr>
        </table>
        <ngx-skeleton-loader *ngIf="isLoading" count="3" [theme]="{
                'border-radius': '15px',
                height: '10px',
                'animation-duration': '1s'
              }">
        </ngx-skeleton-loader>
    </mat-card>

</div>