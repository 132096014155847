import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { UserInterface } from "../interface/user.interface";

@Injectable({
    providedIn: 'root',
})
export class AdministratorDataService {
    private basePath: string = `${environment.url}/api/Administrator/`;
    constructor(private http: HttpClient) {
    }

    login(email: string, password: string) {
        return this.http.get(`${this.basePath}Login/?email=${email}&password=${password}`, { responseType: 'text' });
    }

    getProfile(){
        return this.http.get<UserInterface>(`${this.basePath}Profile`);
    }
}