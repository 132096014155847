import { Component } from "@angular/core";
import { UserService } from "src/app/share/service/user.service";
import { AuthService } from "../../share/service/auth.service";

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
})
export class AppDrawerComponent {

  panelOpenState = false;
  constructor(private authService: AuthService,public userService:UserService) {
    
  }

  logout() {
    
    this.authService.logout();
  }
}
