<div>
  <div mat-dialog-title>
    Change {{getTitle()}} to <b>{{getPlanName()}} plan</b>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div style="margin-top: 5px;"></div>
    <mat-form-field style="width:100%">
      <mat-label>Duration Option</mat-label>
      <mat-select [(ngModel)]="durationOption" (selectionChange)="durationChange()" required>
        <mat-option value="d">Daily</mat-option>
        <mat-option value="w">Weekly</mat-option>
        <mat-option value="M">Monthly</mat-option>
        <mat-option value="Q">Quarterly</mat-option>
        <mat-option value="y">Yearly</mat-option>
        <mat-option value="c">Custom</mat-option>
        <mat-option value="s">Unlimited Duration</mat-option>
      </mat-select>
    </mat-form-field>


    <ng-container *ngIf="durationOption!='s'">
      <ng-container *ngIf="durationOption!='c'">
        <mat-form-field style="width:100%">
          <mat-label>Duration</mat-label>
          <input matInput [(ngModel)]="duration" required (input)="durationChange()" mask="separator.0"
            thousandSeparator="," />
        </mat-form-field>
        <mat-form-field style="width:100%" (click)="picker.open()">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="picker" readonly [(ngModel)]="startDate" required>
          <mat-datepicker #picker (closed)="durationChange()"></mat-datepicker>

        </mat-form-field>
        <mat-form-field style="width:100%" (click)="picker2.open()">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="picker2" disabled [(ngModel)]="endDate" required>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <mat-form-field style="width:100%" (click)="picker3.open()" *ngIf="durationOption=='c'">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker3">
          <input matStartDate [(ngModel)]="startDate" placeholder="Start date" required readonly>
          <input matEndDate [(ngModel)]="endDate" placeholder="End date" required readonly>
        </mat-date-range-input>
        <mat-date-range-picker #picker3></mat-date-range-picker>
      </mat-form-field>
    </ng-container>


  </div>
  <div mat-dialog-actions>
    <button mat-button class="sm" [mat-dialog-close]="false">
      Cancel
    </button>
    <button mat-flat-button (click)="save()" color='primary' class="sm">
      Ok
    </button>
  </div>
</div>