import {
  Directive,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";

@Directive({
  selector: "[show]",
})
export class ScreenDirective {
  private _value: "mobile" | "desktop" | "tablet" | "not-mobile";
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }
  @Input()
  set show(value: "mobile" | "desktop" | "tablet" | "not-mobile") {
    this._value = value;
    this.updateView();
  }

  @HostListener("window:resize", [])
  onResize() {
    this.updateView();
  }

  private updateView() {
    this.viewContainer.clear();
    if (this._value == "not-mobile") {
      if (window.innerWidth <= 768) this.viewContainer.clear();
      else this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      if (this._value == "mobile") {
        if (window.innerWidth <= 768)
          this.viewContainer.createEmbeddedView(this.templateRef);
        else this.viewContainer.clear();
      }
      else if (this._value == "tablet") {
        if (window.innerWidth > 768 && window.innerWidth < 1024)
          this.viewContainer.createEmbeddedView(this.templateRef);
        else this.viewContainer.clear();
      }
      else if (this._value == "desktop") {
        if (window.innerWidth >= 1024)
          this.viewContainer.createEmbeddedView(this.templateRef);
        else this.viewContainer.clear();
      }
    }


  }
}
