import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { CompanyListingInterface } from "../interface/company.interface";

@Injectable({
    providedIn: 'root',
})
export class CompanyDataService {
    private basePath: string = `${environment.url}/api/Company/`;
    constructor(private http: HttpClient) {
    }

    getListing() {
        return this.http.get<CompanyListingInterface[]>(`${this.basePath}GetListing`);
    }

    changeStatus(ids: string[], isActive: boolean) {
        return this.http.post(`${this.basePath}ChangeStatus`, { ids, isActive });
    }

    changePlan(value:any) {
        return this.http.post(`${this.basePath}changePlan`, value);
    }

}